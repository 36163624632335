@import './src/variables';

.deltaStats {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-width: 340px;

    .stats {
        display: flex;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-width: 0;
    }
}
