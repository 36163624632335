@import "../../../variables";

.appBar {
    background: var(--color-app-bar);

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .menuIcon {
        display: none;

        img {
            @media (prefers-color-scheme: dark) {
                filter: invert(0.7);
            }
        }
    }

    @media screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3){
        .menuIcon {
            display: initial;
        }
    }
}

