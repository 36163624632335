@import '../../../variables';
@import './clientVariables';

.clientBody{
    display: flex;
    max-height: calc(100vh - #{$heightOutsideCards});

    >:last-child{
        flex-grow: 1;
        position: relative;
    }

    @media only screen and (max-width: $mobileWidth) {
        flex-direction: column;
        max-height: unset;
    }

}
