.formField {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 250px;

    .label {
        width: 30%;
        font-weight: 400;
        font-family: 'Biotif', sans-serif;
    }

    >:last-child{
        width: 70%;
    }
}