@import "../../../../mixins";
@import "../clientVariables";

.clientDocuments {
    $padding: 24px;
    padding: $padding;
    max-height: calc(100vh - #{$heightOutsideTabClientContent} -  #{$padding * 2});
    overflow: auto;

    > * {
        margin-bottom: 24px;
    }

    img {
        @include elevation2x;
        border-radius: var(--radius-default);
        max-width: 45vw;
    }

    > :last-child {
        display: flex;
    }

    .infos {
        display: flex;

        > * {
            width: 50%;

            > * {
                margin: 8px 16px;
            }
        }

        .relatedPerson {
            font-size: var(--font-size-densed);
            display: block;
        }

        h3 {
            margin: 0;
        }
    }

    @media screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        max-height: calc(100vh - #{$heightOutsideTabClientContentMobile});

        img {
            max-width: 100%;
        }
    }
}
