@import '../../mixins';
@import '../../variables';

.referrals {
    .tableReferrers {
        table {
            width: 100%;

            td, th {
                &:last-child {
                    width: 3%;
                }
            }

            tr:nth-child(even) td {
                background: var(--color-background-light);
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        @include hideCard;
        margin-bottom: 32px;

        .tableReferrers > table {
            @include lastTdAsAction;

            tr {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
                overflow: hidden;
            }

            td {
                $padding: 16px;
                padding: 8px $padding;

                &:nth-child(1){
                    width: 100%;
                }

                &:last-child {
                    width: unset;
                }
            }
        }
    }
}
