@import '../../../mixins';
@import '../../../variables';

.switch {
    display: flex;
    align-items: center;

    .toggle {
        position: relative;

        border-radius: 20px;

        left: 0;
        width: 44px;
        height: 18px;

        cursor: pointer;

        $transitionDuration: 0.3s;
        transition: all $transitionDuration;

        &::after {
            @include elevation;

            transition: all $transitionDuration;

            content: ' ';
            position: absolute;
            top: -3px;

            width: 24px;
            height: 24px;

            border-radius: 50%;
        }

        &.isOff {
            background: var(--color-switch-off);

            &::after {
                background: var(--color-switch-background-off);
            }
        }

        &.isOn {
            background: var(--color-switch-background);

            &::after {
                background: var(--color-selected);
                transform: translateX(20px);
            }
        }
    }

    .label {
        padding-left: 8px;
        color: var(--color-font-input);
        font-size: 14px;
    }
}
