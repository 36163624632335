@import "../../mixins";

.clients {
    .tableClients {
        table {
            width: 100%;

            td, th {
                &:last-child {
                    width: 3%;
                }
            }

            tr:nth-child(even) td {
                background: var(--color-background-light);
            }

            td {
                cursor: pointer;
                padding: 0;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        @include hideCard;
        margin-bottom: 32px;

        .tableClients {
            padding: 0 16px;

            table {
                @include lastTdAsAction;

                tr {
                    flex-wrap: wrap;
                    flex-direction: row;
                    overflow: hidden;

                    td {
                        width: 32%;

                        padding: 8px 16px;

                        &:last-child {
                            width: unset;
                        }
                    }
                }
            }
        }
    }
}