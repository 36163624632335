@import '../../../variables';
@import '../../../mixins';

.paginator {
    padding: 8px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    > :not(:last-child) {
        margin-left: 16px;
    }

    span {
        font-size: 12px;
        font-weight: 300;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        justify-content: space-between;
        justify-items: center;

        position: fixed;
        bottom: 0;
        @include elevation();

        width: calc(100% - 16px);
        left: 0;

        background: var(--color-background-dialog);

        > :not(:last-child) {
            margin-left: 0;
        }

        > :nth-child(1) {
            display: none;
        }

        :nth-child(3) {
            order: -1;
        }
    }
}
