@import "../../../mixins";
@import "../../../variables";
@import "../../../mixins";

.renewTokenHelper {
    @include elevation;
    background-color: var(--color-background-light);
    border-radius: var(--radius-default);

    position: absolute;
    bottom: 24px;
    right: 24px;
    width: 240px;
    padding: 16px;

    opacity: 1;
    transition: opacity .3s ease-in-out;

    z-index: 2;

    .label {
        display: block;
        margin-bottom: 16px;
    }

    .action{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .expireInTime{
            opacity: .6;
            padding-right: 16px;
        }
    }

    .progressBar {
        display: none;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        $paddingHorizontal: 16px;
        padding: 8px $paddingHorizontal;

        bottom: 0;
        width: calc(100% - #{$paddingHorizontal * 2});
        right: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        border-radius: 0;

        .label{
            margin-bottom: 0;
        }

        .progressBar{
            display: block;
            position: absolute;
            top: 0;
            height: 2px;
            background: var(--color-error);
            left: 0;
        }
    }
}