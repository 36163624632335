@import '../../../../mixins';
@import '../../../../variables';

.clientCommissionContract {
  margin: 24px 16px;

  .field {
    margin: 16px;
    max-width: 180px;
  }

  .referralTitle {
    display: none;
  }

  .referralTable {
    table {
      width: 100%;

      tr:nth-child(even) td {
        background: var(--color-background-light);
      }

      td {
        padding: 0;

        .referralCode {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
    margin: 16px;

    .referralTitle {
      display: flex;
      align-items: center;

      span {
        margin-right: 4px;
      }
    }

    .referralTable > table {
      @include lastTdAsAction;

      tr {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;

        &.main {
          background: var(--color-primary-hover);
          color: var(--color-font-on-primary);
        }
      }

      td {
        $padding: 16px;
        padding: 8px $padding;

        .referralCode {

          span {
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          justify-content: left;
        }

        &:nth-child(1){
          width: 40%;
        }

        &:nth-child(2){
          width: 40%;
        }

        &:nth-child(3) {
          width: unset;
        }
      }
    }
  }
}
