@import "../../mixins";

.dashboard{
    @include page;
    
    .info{
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 24px;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        .info {
            flex-wrap: nowrap;
            flex-direction: column;
        }
    }
}
