@import "../../../../variables";

.dialogAddressClient {
    min-width: 400px;

    .field {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;

        > * {
            margin-left: 16px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        min-width: unset;
    }
}