@import "../../../../variables";
@import "../../../../mixins";


.details {
  @media screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
    @include hideCard;
       border-radius: unset;

        .detailsBody {
            padding: 0;
        }

        &.isProfileOpened {
          display: none;
        }
    }
}