@import '../../../../variables';

.editClient {
    min-width: 400px;

    .field {
        margin-bottom: 16px;
    }

    .actions {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;

        > * {
            margin-left: 16px;
        }
    }

    .label {
        font-weight: 600;
        font-size: 12px;

        color: var(--color-font-input);
        opacity: .7;

        margin-bottom: 4px;

        cursor: default;
    }

    .toggle {
        margin-top: 24px;
    }

    .birthDate {
        align-items: center;

        > :last-child {
            color: var(--color-font-input);
            opacity: 1;
        }

        span {
            margin: 4px 0 8px 8px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        min-width: unset;
    }
}
