.statsDetail {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.green span:first-child{
        color: green;
    }

    &.red span:first-child{
        color: var(--color-error);
    }

    span:first-child{
        font-size: 10px;
        font-weight: bold;
    }

    span:last-child{
        font-size: 8px;
        font-weight: bolder;
        opacity: .6;
    }
}
