@import "../../../../variables";

.clientProfile {
    width: 20%;
    overflow: auto;

    .clientProfileHeader {
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            margin-top: 8px;
            margin-bottom: 16px;
        }

        .avatarName {
            display: flex;
            align-items: center;
        }

        .address {
            display: flex;
            align-items: center;

            :first-child {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: right;
                max-width: 150px;
            }
        }
    }

    .clientProfileBody > * {
        margin-top: 16px;

        .activeStatus img {
            filter: invert(20%) sepia(76%) saturate(4614%) hue-rotate(357deg) brightness(97%) contrast(100%);
        }

        .frozenStatus, .pendingApproval .lockedPasswordStatus {
            img {
                filter: invert(33%) sepia(37%) saturate(1955%) hue-rotate(83deg) brightness(101%) contrast(88%);
            }
        }
    }

    .actions {
        margin-top: 32px;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        width: 100vw;
        margin: 0;
        padding: 0;

        display: flex;
        justify-content: center;

        border-radius: 0;

        &:not(.profileIsOpened) {
            overflow: unset;

            .clientProfileHeader {
                display: flex;
                padding: 0;

                .avatar, .editPersonButton, .address {
                    display: none;
                }

                .avatarName {
                    padding-bottom: 0;
                    margin: 0;
                    width: 100%;
                    justify-content: center;

                    h3 {
                        padding-bottom: 0;
                        font-size: 13px;
                    }
                }
            }

            .clientProfileBody, .actions {
                display: none;
            }

            .contentCard {
                padding: 8px;
            }
        }

        &.profileIsOpened {
            top: $sizeAppBar;
            height: max-content;

            .clientProfileHeader .avatarName {
                &:before {
                    content: '<-';
                    position: absolute;
                    top: 85px;
                    left: 16px;
                    font-size: 24px;
                }
            }
        }

        .clientProfileBody {
            padding: 16px 32px;
        }
    }
}
