@import "../../../mixins";
@import "../../../variables";

.operator {
    .form {
        margin-top: 16px;
        width: 50%;
        
        .button {
            margin-top: 24px;
            width: 25%;
        }
    }
}
