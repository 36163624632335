@import '../../../mixins';
@import '../../../variables';

.successTransferEthereum {
    min-width: 400px;

    .successMessage {
        max-width: 360px;
        margin: 36px 0;

        a {
            text-decoration: none;
            color: var(--color-primary-button);
        }
    }

    .action {
        display: flex;
        justify-content: flex-end;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-width: unset;
    }
}
