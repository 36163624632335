@import '../../../variables';
@import '../../../mixins';

.info {
    padding: 16px 0;
    display: flex;
    color: var(--color-font);
}

.depositNoticeList {
    table {
        @include lastTdAsAction;
        width: 100%;

        tbody {
            padding: 16px;
        }
    }
}