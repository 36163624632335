$sizePaddingApp: 8px;
$sizeAppBar: 74px;
$sizeBreadCrumb: 23px;
$fontSize: 14px;

$sizeMarginCard: 24px;

$mobileWidth: 428px;
$laptopWidth: 883px;


