@import "../../variables";

.settings {
    h3 {
        margin-top: 40px;
    }

    .withdrawReal {
        display: flex;
        align-items: center;

        span {
            font-size: 32px;
            margin: 0 16px;
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        .withdrawReal {
            display: unset;
        }

        .discardButton{
            display: none;
        }
    }
}
