@import "../../../../variables";

.depositNotice {
    padding-top: 40px;

    table {
        width: 100%;

        .red {
            color: var(--color-error);
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        padding-top: 0;
    }
}