@import "../../../mixins";
@import "../../../variables";

.operator {
    .form {
        margin-top: 16px;
        width: 50%;

        .alert {
            position: relative;
            cursor: default;
    
            margin: 8px 0;
            padding: 8px 16px;
    
            font-size: $fontSize;
            text-align: center;
            color: white;
            background-color: var(--color-error);
        }

        .button {
            margin-top: 24px;
            width: 25%;
        }
    }
}
