@import "../../../../../mixins";

.transactionDetail{
    margin: 8px 16px;
    position: relative;

    @include elevation;

    display: flex;
    flex-direction: column;
    padding: 24px;
    min-width: 200px;

    background: var(--color-transaction);

    .detailBody{
        &:before{
            content: ' ';
            height: 12px;
            width: 100%;
            border-bottom: 2px dashed var(--color-border-field);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h1, h2, h3{
        text-align: center;
        margin: 24px 0;
    }

    .info{

        margin: 8px 0;

        >*{
            padding-bottom: 8px;
        }

        &.withdraw>*{
           width: 100%;
        }

        &.deposit>*{
            width: 100%;
        }
    }

    table th{
        font-size: .8em;
        padding: 8px;
    }

    &:before{
        content: ' ';
        width: 108%;
        height: 6px;
        background-color: var(--color-primary-hover);
        position: absolute;
        top: -6px;
        left: -4%;
        border-radius: var(--radius-default);
    }
}
