.addCreditAnnotation {
    padding: 16px 0 24px 0;
    min-width: 400px;

    > * {
        margin: 24px 0;
    }
    .negative{
        color: red;
    }
    .positive{
        color: green;
    }
}

