@import '../../../mixins';
@import '../../../variables';

.searchEthereumAddress {
    min-width: 460px;
    min-height: 300px;

    .iconSearch {
        filter: invert(1);
    }

    .tableAddress {
        max-height: 200px;
        overflow-y: auto;
        margin-top: 16px;

        table {
            width: 100%;

            tr {
                cursor: pointer;

                &:hover td {
                    background: var(--color-icon-button-hover);
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-width: unset;

        .tableAddress {
            max-height: 40vh;
            padding: 8px;

            tr {
                margin: 8px 0;
            }
        }
    }
}
