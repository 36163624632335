@import "../../../mixins";

.floatingActionButton{
    @include elevation2x;

    position: absolute;

    $padding: 32px;
    bottom: $padding;
    right: $padding;

    $size: 48px;
    height: $size;
    width: $size;

    border-radius: 50%;

    background: var(--color-primary-button);
    color: var(--color-font-on-primary);

    cursor: pointer;

    >*{
        @include centered;
    }

    &:hover{
        background: var(--color-primary-hover);
    }

    .content{
        filter: invert(1);
    }
    
    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        position: fixed;
    }
}
