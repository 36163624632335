@import './src/variables';

.summaryItem {
    .coinValues {
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
        
        @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        }
    }
}
