@import "../../../mixins";
@import "../../../variables";

.snackBar{
    @include elevation;
    z-index: 3;

    position: fixed;
    bottom: 5%;
    left: 50%;

    padding: 8px 48px;

    background-color: var(--color-background-light);

    font-weight: 200;
    border-radius: var(--radius-default);

    font-size: .9em;

    opacity: 0;
    transform: translate(-50%, 150%)  scale(0.8);

    transition-property: transform, opacity;
    transition-duration: .2s;
    &.show{
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        $padding: 8px;
        $margin: 8px;

        padding: $padding;
        margin: $margin;
        width: calc(100% - #{($padding + $margin) * 2});
        left: 0;

        bottom: 0;
        background: var(--color-background-field);
        text-align: center;

        transform: translate(0, 150%)  scale(0.8);

        &.show{
            transform: translate(0, 0) scale(1);
        }
    }
}
