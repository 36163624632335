@import "../../mixins";

.cardOperators {
  .tableOperators {
    table {
      width: 100%;

      td, th {
        &:last-child {
          width: 3%;
        }
      }

      tr:nth-child(even) td {
        background: var(--color-background-light);
      }

      td {
        cursor: pointer;
        padding: 0;
      }
    }

    .noItemOnList {
      padding: 16px 0;
      display: block;
      color: #dab31f;
    }
  }

  @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
    @include hideCard();
    margin-bottom: 32px;

    .tableOperators table {
      @include lastTdAsAction;

      tr {
        flex-wrap: wrap;
        flex-direction: row;

        td {
          width: 32%;
          padding: 8px 16px;

          &:last-child {
            width: unset;
          }
        }
      }
    }
  }
}
