@import "../../../mixins";

.label{
    &:first-child{
        margin-top: 0;
    }

    &:last-child{
        margin-bottom: 0;
    }

    .title{
        @include labelTitle;
    }

    .content{
        font-weight: 400;
        font-size: var(--font-size-densed);

        font-family: "Biotif", sans-serif;

        display: flex;
        max-width: 50vw;
        overflow-wrap: anywhere;
    }
}
