.clientWallets{
    padding: 24px;
    display: flex;
    flex-direction: column;

    >*{
        margin-bottom: 16px;
    }
    >div{
        margin-left: 16px;
    }
}
