.body {
    margin-top: 40px;

    h5 {
        margin: 16px 0 8px;
    }

    .actions {
        margin-top: 32px;
        display: flex;

        .image {
            margin-right: 8px;
            filter: invert(1);
        }
    }

    .error {
        font-size: 12px;
        color: var(--color-error);
    }
}

