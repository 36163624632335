@import '../../mixins';
@import '../../variables';

.tronWallets {
    .tableWallets {
        table {
            width: 100%;

            td, th {
                &:last-child {
                    width: 3%;
                }
            }

            tr:nth-child(even) td {
                background: var(--color-background-light);
            }

            tr.main td {
                background: var(--color-primary-hover);
                color: var(--color-font-on-primary);

                img {
                    filter: invert(1);
                }
            }

            td {
                padding: 0;

                .address {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    width: 13%;
                }

                .amount{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        @include hideCard;
        margin-bottom: 32px;

        .tableWallets > table {
            @include lastTdAsAction;

            tr {
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;
                overflow: hidden;

                &.main {
                    background: var(--color-primary-hover);
                    color: var(--color-font-on-primary);
                }
            }

            td {
                $padding: 16px;
                padding: 8px $padding;

                .address {

                    span {
                        max-width: 140px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    justify-content: left;
                }

                &:nth-child(1){
                    width: 20%;
                }

                &:nth-child(2){
                    width: 60%;
                }

                &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                    width: 20%;
                }

                &:nth-child(6) {
                    width: unset;
                }
            }
        }
    }
}
