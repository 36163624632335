
.field {
    margin: 8px 0;
    line-height: 12px;

    padding: 8px;

    border-bottom: 1px solid var(--color-border-field);

    transition: all 0.3s;
    background: var(--color-background-light);

    .label {
        display: block;

        font-weight: 600;
        font-size: 12px;

        width: 100%;

        margin-bottom: 4px;

        opacity: .7;

        cursor: default;
        color: var(--color-font);
    }

    .input {
        display: flex;
        align-items: center;
    }

    .suffix,
    .prefix {
        color: gray;
    }

    .prefix {
        margin-right: 8px;
    }

    &:focus-within,
    &.disabled {
        background: var(--color-background-field);
        border-radius: var(--radius-default);
        border-color: transparent;

        .label {
            opacity: 1;
        }
    }

    &:focus-within {
        .suffix,
        .prefix {
            color: var(--color-selected);
        }
        input, textarea {
            color: var(--color-font-active);
        }
        .label{
            color: var(--color-selected);
        }
    }

    input, textarea {
        display: block;
        margin: 0;
        border: none;
        flex-grow: 1;

        outline: none;

        background: transparent none repeat scroll 0 0;

        font-size: 14px;
        line-height: 22px;
        color: var(--color-font-input);

        &::placeholder {
            color: rgb(158, 158, 158);
        }
    }

    &.hasError {
        border-color: var(--color-error);
    }
}

.error {
    font-size: 10px;
    color: var(--color-error);
}
