@import '../../../variables';

.addCommissionReferral {
    min-width: 400px;

    .form {
        padding: 16px 0;

        .field {
            margin-bottom: 16px;

            &:last-child {
                margin-top: 16px;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-width: unset;
    }
}
