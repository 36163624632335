.actions {
    display: flex;
    justify-content: space-around;

    >*{
        width: 50%;
        margin: 0 16px;
    }

    .approve{
        background: var(--color-success);
    }
}