@import '../../../mixins';
@import '../../../variables';

.pendingWithdraws {
    .withdraws {
        table {
            width: 100%;

            td {
                .wrapperTd {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        @include hideCard;

        .withdraws > table {
            @include lastTdAsAction;

            td .wrapperTd {
                justify-content: left;
            }
        }
    }
}
