@import "../../../mixins";

.card {
    @include baseCard;

    min-width: 200px;
    padding: 24px;

    h3 {
        padding-bottom: 16px;
    }

    .cardBody {
        padding: 16px
    }

    &.primary {
        background: var(--color-primary);
        color: var(--color-font-on-primary);
    }

    &.default {
        background: var(--color-background-light);
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        margin: 16px 0;
    }
}
