@import '../../../variables';

.depositNoticeDetail {
    $paddingRightLabel: 8px;
    .content {
        display: flex;
        margin-top: 16px;

        .info {
            display: block;

            border-right: solid 1px var(--color-font-active);

            margin: 8px 0 0 0;
            padding-right: 24px;
            max-width: 400px;

            > * {
                width: 50%;
                padding: 8px $paddingRightLabel 8px 0;

                &:last-child {
                    width: 100%;
                }
            }

            table {
                width: 100%;
                margin: 24px 0;

                td {
                    width: 10%;
                }
            }
        }

        .img {
            overflow: auto;
            max-height: 50vh;
            margin-left: 16px;
            max-width: 35vw;

            img {
                max-width: 100%;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        .content {
            flex-direction: column-reverse;

            .info {
                border-right: none;
                padding-right: 0;
                max-width: unset;

                display: flex;
                flex-wrap: wrap;

                > * {
                    width: calc(50% - #{$paddingRightLabel});
                }

                table {
                    margin: 0;

                    tr {
                        flex-direction: row;
                        margin: 8px 0;
                        flex-wrap: wrap;

                        td {
                            width: unset;
                        }
                    }
                }
            }

            .img {
                margin-left: 0;
                margin-bottom: 16px;
                max-width: unset;
                max-height: 30vh;

            }
        }
    }
}