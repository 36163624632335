@import '../../../mixins';
@import '../../../variables';

.addReferral {
    min-width: 400px;

    .form {
        padding-bottom: 16px;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        min-width: unset;
    }
}
