@import './variables';

.app{
    $padding: 8px;

    display: flex;

    height: calc(100vh - #{$padding * 2});
    width: calc(100vw - #{$padding * 2});

    padding: $padding;

    background: var(--color-primary-darker);
    .content{
        background: var(--color-primary-lighter);
        flex-grow: 1;

        overflow-y: auto;

        border-top-right-radius: var(--radius-whole-app);
        border-bottom-right-radius: var(--radius-whole-app);
    }
    
    @media only screen and  (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        height: max-content;
        width: 100vw;

        .content{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        padding: 0;
    }
}
